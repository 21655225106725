import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useGetEmailGroups from '../../hooks/useGetEmailGroups';
import useGetLoadUsers from '../../hooks/useGetLoadUsers';
import useGetLoadWeekWithWigramEmailReceivers from '../../hooks/useGetLoadWeekWithWigramEmailReceivers';
import { StepContext } from '../../App';

const Step3 = ({ onWatchStep3 }) => {
  //! Context
  const store = useContext(StepContext);

  //! State
  const { emailGroups } = useGetEmailGroups();
  const { users } = useGetLoadUsers();
  const { optedInEmails } = useGetLoadWeekWithWigramEmailReceivers();

  const [emailTo, setEmailTo] = useState(
    store?.value?.step3Data?.emailTo || '',
  );
  const [emailsTo, setEmailsTo] = useState(
    store?.value?.step3Data?.emailsTo || [],
  );
  const [selectedEmailGroup, setSelectedEmailGroup] = useState(
    store?.value?.step3Data?.selectedEmailGroup || {},
  ); // email group with id = 1
  const [checked, setChecked] = React.useState(
    store?.value?.step3Data?.checked || optedInEmails || [],
  );
  const [sendToTabValue, setSendToTabValue] = useState('single');

  //! Effect
  // Given a list of userIds, return a list of user emails
  const userEmails = useCallback(
    (userIds = []) => {
      return users
        ?.filter((u) => userIds.includes(u.user_data?.id))
        ?.map((u) => u.email);
    },
    [users],
  );

  useEffect(() => {
    if (selectedEmailGroup) {
      const emailGroup = emailGroups.find(
        (g) => g.id === selectedEmailGroup.id,
      );
      if (emailGroup) {
        setEmailsTo(userEmails(emailGroup?.userIds));
      } else {
        setChecked(optedInEmails);
      }
    }
  }, [optedInEmails, emailGroups, userEmails, selectedEmailGroup]);

  useEffect(() => {
    onWatchStep3 &&
      onWatchStep3({
        emailTo,
        emailsTo: sendToTabValue === 'optedIn' ? checked : emailsTo,
        emailGroups,
        sendToTabValue,
        selectedEmailGroup,
      });
  }, [
    onWatchStep3,
    emailTo,
    emailsTo,
    emailGroups,
    sendToTabValue,
    selectedEmailGroup,
    checked,
  ]);

  //! Function
  const handleTabChange = (event, newValue) => {
    setSendToTabValue(newValue);
    setSelectedEmailGroup({}); // Reset email-group value
  };

  const handleEmailGroupChange = (event) => {
    setSelectedEmailGroup(event.target.value);
  };

  const handleToggleEmailReceiver = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  //! Render
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={sendToTabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="tabs">
              <Tab label="Single Email Address" value="single" />
              <Tab
                label="Opted-In for `The week with Wigram`"
                value="optedIn"
              />
              <Tab label="Email Group" value="group" />
            </TabList>
          </Box>
          <TabPanel value="single" sx={{ p: 0, pt: 4 }}>
            {/* Single Email Address */}
            <TextField
              id="outlined-basic"
              label="Email To"
              variant="outlined"
              fullWidth
              value={emailTo}
              sx={{ width: '50ch' }}
              onChange={({ target: { value } }) => {
                setEmailTo(value);
              }}
            />
          </TabPanel>
          <TabPanel value="optedIn" sx={{ p: 0, pt: 2 }}>
            <Paper style={{ maxHeight: 400, overflow: 'auto' }}>
              <List sx={{ width: '100%', maxWidth: 360 }}>
                {/*{[0, 1, 2, 3].map((value) => {*/}
                {(optedInEmails || []).map((value) => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                    <ListItem key={value} disablePadding>
                      <ListItemButton
                        role={undefined}
                        dense
                        divider
                        onClick={handleToggleEmailReceiver(value)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.includes(value)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value}`} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          </TabPanel>
          <TabPanel value="group" sx={{ p: 0, pt: 4 }}>
            {/* Email Group */}
            {emailGroups?.length > 0 && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Email To Group
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ width: '50ch' }}
                  value={selectedEmailGroup}
                  label="Email To Group"
                  onChange={handleEmailGroupChange}
                >
                  {emailGroups.map((group) => (
                    <MenuItem value={group} key={group.id}>
                      {`${group.name} (${group.userCount || 0} clients)`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default React.memo(Step3);
