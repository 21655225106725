import React from 'react';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const theme = createTheme();

export default function ConfirmSend({
  subject,
  numberOfUsers,
  sender,
  open,
  setOpen,
  sendEmailStatus,
  sentMessage,
  handleSend,
}) {
  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} className="confirm">
        <DialogTitle>Send: {subject}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!sendEmailStatus && (
              <>
                Confirm to send email to{' '}
                {numberOfUsers !== 1
                  ? `${numberOfUsers} users`
                  : `${numberOfUsers} user`}{' '}
                from {sender}
              </>
            )}

            {sendEmailStatus === 'sending' && 'Email Sending…'}

            {sendEmailStatus === 'sent' && sentMessage ? sentMessage : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!sendEmailStatus && (
            <>
              <Button
                sx={{
                  color: 'inherit',
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleSend}>Send</Button>
            </>
          )}

          {sendEmailStatus === 'sending' && <></>}

          {sendEmailStatus === 'sent' && (
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
