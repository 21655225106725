import React from 'react';
import { Box, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import slice from 'lodash/slice';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'title',
    headerName: 'Article Title',
    width: 600,
    editable: false,
  },
];

// Disable cell focus
const CustomDataGrid = styled(DataGrid)(() => ({
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
}));

const Step4 = ({
  articlesToSend,
  weekWithWigramTitle,
  weekWithWigramDescription,
  sendToTabValue,
  emailTo,
  emailsTo,
  emailFrom,
  subject,
  selectedEmailGroup,
}) => {
  console.log('selectedEmailGroup', selectedEmailGroup);
  return (
    <>
      <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
        Review `The week with Wigram` email before sending
      </Typography>
      <Typography variant="p" component="p" sx={{ mb: 2 }}>
        <b style={{ color: '#5b85d7' }}>{articlesToSend?.length} article(s)</b>{' '}
        will be sent:
      </Typography>
      <Box
        sx={{
          height: 450,
          width: '100%',
          backgroundColor: '#233246',
        }}
      >
        <CustomDataGrid
          rows={articlesToSend}
          columns={columns}
          pageSize={6}
          rowsPerPageOptions={[50]}
          // isCellEditable={false}
          disableSelectionOnClick
        />
      </Box>
      <Typography variant="p" component="p" sx={{ mt: 4, mb: 4 }}>
        Email Sender Address / From:{'  '}
        <b style={{ color: '#5b85d7' }}>{emailFrom}</b>
      </Typography>
      {/* email subject  */}
      {subject !== '' && (
        <Typography variant="p" component="p" sx={{ mt: 4 }}>
          Email Subject: <b style={{ color: '#5b85d7' }}>{subject}</b>
        </Typography>
      )}
      <Typography variant="p" component="p" sx={{ mt: 4, mb: 4 }}>
        Title in `Week with Wigram` email: {'  '}
        <b style={{ color: '#5b85d7' }}>{weekWithWigramTitle}</b>
      </Typography>
      <Typography variant="h6" component="h6" sx={{ pt: 4, mb: 2 }}>
        Wigram Intro Text/Comment for `The week with Wigram` email:
      </Typography>
      <TextField
        id="outlined-basic"
        label=""
        variant="outlined"
        fullWidth
        multiline={true}
        minRows={10}
        value={weekWithWigramDescription}
        sx={{ mb: 2, mt: 1 }}
        InputProps={{
          readOnly: true,
        }}
      />

      {sendToTabValue === 'single' && (
        <>
          <Typography variant="p" component="p" sx={{ mt: 4 }}>
            To{' '}
            <b style={{ color: '#5b85d7' }}>
              {emailTo ? 1 : 0} email {emailTo ? 'address' : 'addresses'}
            </b>
            :
          </Typography>

          <ul>
            <li>{emailTo}</li>
          </ul>
        </>
      )}

      {sendToTabValue === 'group' && (
        <>
          <Typography variant="p" component="p" sx={{ mt: 4 }}>
            To{' '}
            <b style={{ color: '#5b85d7' }}>{`${
              selectedEmailGroup?.name || ''
            } (${selectedEmailGroup?.userCount || 0} users)`}</b>
            :
          </Typography>

          <ul>
            {slice(emailsTo, 0, 10)?.map((email, i) => (
              <li key={i}>{email}</li>
            ))}
          </ul>

          {selectedEmailGroup?.userCount > 10 && (
            <Typography
              variant="p"
              component="p"
              sx={{ mt: 2, fontStyle: 'italic' }}
            >
              +{selectedEmailGroup?.userCount - 10} more
            </Typography>
          )}
        </>
      )}

      {sendToTabValue === 'optedIn' && (
        <>
          <Typography variant="p" component="p" sx={{ mt: 4 }}>
            To{' '}
            <b style={{ color: '#5b85d7' }}>
              {emailsTo?.length || 0} opted-in email addresses
            </b>
            :
          </Typography>
          <ul>
            {emailsTo?.map((email) => (
              <li key={email}>{email}</li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

export default Step4;
