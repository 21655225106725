import { Alert } from '@mui/lab';
import {
  Box,
  Button,
  Fade,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react';
import { Title } from 'react-admin';
import useGetCurrentSession from '../hooks/useGetCurrentSession';
import { API_BASE_URL, CMS_API_BASE_URL } from '../utils/constants';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import { StepContext } from '../App';
import ConfirmSend from '../confirm-send';

// Create a theme that's used for @mui components
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4AA842',
      contrastText: '#222222',
    },
  },
});

if (!CMS_API_BASE_URL) {
  console.error('CMS_BASE_URL is not set!');
} else {
  console.log('CMS_BASE_URL', CMS_API_BASE_URL);
}
if (!API_BASE_URL) {
  console.error('API_BASE_URL is not set!');
} else {
  console.log('API_BASE_URL', API_BASE_URL);
}

const Email = () => {
  //! Context
  const store = useContext(StepContext);

  //! Local
  const initData = JSON.parse(localStorage.getItem('data'));

  //! State
  const { jwtToken } = useGetCurrentSession();
  const [articleType, setArticleType] = useState('longform');
  const [activeStep, setActiveStep] = React.useState(0);
  const [showAlertType, setShowAlertType] = useState('');

  const [selectedShortform, setSelectedShortform] = useState(
    initData?.selectedShortform || [],
  );
  const [selectedLongform, setSelectedLongform] = useState(
    initData?.selectedLongform || [],
  );

  const [selectedRows, setSelectedRows] = useState(
    initData?.selectedRows || [],
  );
  const [step2Data, setStep2Data] = useState(initData?.step2Data || {});
  const [rowDetail, setRowDetail] = useState([]);
  const [isAttachedChart, setAttachedChart] = useState(true);
  const [showConfirmSend, setShowConfirmSend] = useState(false);
  const [sendEmailStatus, setSendEmailStatus] = useState('');

  //! Refesh Click
  useEffect(() => {
    store.setValue({
      selectedShortform,
      selectedLongform,
      selectedRows,
      step2Data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.clickRefesh]);
  useEffect(() => {
    store.setValue({
      selectedShortform,
      selectedLongform,
      selectedRows,
      step2Data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.removeItem('data');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //! Effect
  const modelSelectionCached = useMemo(() => {
    if (articleType === 'shortform') {
      return selectedShortform;
    }

    if (articleType === 'longform') {
      return selectedLongform;
    }
  }, [articleType, selectedLongform, selectedShortform]);

  //! Function

  const onWatchStep2 = useCallback(
    ({
      emailTo,
      emailsTo,
      subject,
      emailGroups,
      selectedEmailGroup,
      sendToTabValue,
      emailFrom,
    }) => {
      setStep2Data({
        emailTo,
        emailsTo,
        subject,
        emailGroups,
        selectedEmailGroup,
        sendToTabValue,
        emailFrom,
      });
    },
    [],
  );

  const onWatchSelectedRow = useCallback(
    (nextRowsSelected, { tab, row, articles }) => {
      let shortformArticle = [];
      let longformArticle = [];
      if (tab === 'shortform') {
        setSelectedShortform(nextRowsSelected);
        shortformArticle = articles.filter((el) =>
          nextRowsSelected.includes(el.id),
        );
        longformArticle = selectedRows.filter(
          (el) => el.strapi_content_type !== 'SHORT_FORM',
        );
      } else if (tab === 'longform') {
        setSelectedLongform(nextRowsSelected);
        shortformArticle = selectedRows.filter(
          (el) => el.strapi_content_type !== 'LONG_FORM',
        );
        longformArticle = articles.filter((el) =>
          nextRowsSelected.includes(el.id),
        );
      }
      setSelectedRows([...shortformArticle, ...longformArticle]);
    },
    [selectedRows],
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setRowDetail(selectedRows);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const sendEmail = () => {
    const { emailFrom, emailTo, emailsTo, subject } = step2Data;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const pdfFilter = selectedRows.map((obj) => obj.pdf);
    const urlValue = pdfFilter[0]?.url;

    let requestBody = {
      email_receivers: emailTo ? [emailTo] : emailsTo,
      subject: subject,
      email_sender: emailFrom,
      longform_id: selectedLongform?.[0] || null,
      // shortform_ids: selectedShortform,
    };
    if (pdfFilter && urlValue && isAttachedChart) {
      requestBody = {
        ...requestBody,
        longform_chart_attachments: [urlValue],
      };
    }

    setSendEmailStatus('sending');

    fetch(`${API_BASE_URL}/api/v1/sec/email/send-templated-email`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          setShowAlertType('success');
          return response.json();
        } else {
          setShowAlertType('error');
        }
      })
      .then((data) => console.log(data))
      .catch((err) => setShowAlertType('error'))
      .finally(() => {
        setSendEmailStatus('sent');
      });
  };

  const articleTab = useMemo(() => {
    return [
      // {
      //   label: 'shortform',
      //   index: 0,
      // },
      {
        label: 'longform',
        index: 0,
      },
    ];
  }, []);

  useEffect(() => {
    if (sendEmailStatus && !showConfirmSend) {
      // Wait for dialog to close before reset state
      setTimeout(() => {
        setSendEmailStatus('');
      }, 300);
    }
  }, [sendEmailStatus, showConfirmSend]);

  //! Render
  return (
    <ThemeProvider theme={theme}>
      <Title title="WCA Platform | Email Manager | Longform" />

      <Box sx={{ p: 2 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>Choose Articles</StepLabel>
            <StepContent>
              <Box sx={{ p: 2 }}>
                {articleTab.map((item) => {
                  return (
                    item.label === articleType && (
                      <Step1
                        key={item.index}
                        sx={{ mb: 2 }}
                        articleType={articleType}
                        setArticleType={setArticleType}
                        allowMultiSelect={articleType === 'shortform'}
                        modelSelectionCached={modelSelectionCached}
                        onWatchSelectedRow={onWatchSelectedRow}
                      ></Step1>
                    )
                  );
                })}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                  disabled={selectedRows.length === 0}
                >
                  Continue
                </Button>
              </Box>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Include Chart Attachment</StepLabel>
            <StepContent>
              <Box sx={{ p: 2 }}>
                <Step2
                  sx={{ mb: 2 }}
                  rowDetail={rowDetail}
                  isAttachedChart={isAttachedChart}
                  setAttachedChart={setAttachedChart}
                />
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, mr: 1 }}
                  >
                    Continue
                  </Button>
                  <Button onClick={handleBack} sx={{ mt: 3, mr: 1 }}>
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Send To</StepLabel>
            <StepContent>
              <Box sx={{ p: 2 }}>
                <Step3 sx={{ mb: 2 }} onWatchStep2={onWatchStep2} />
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, mr: 1 }}
                  >
                    Continue
                  </Button>
                  <Button onClick={handleBack} sx={{ mt: 3, mr: 1 }}>
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Review & Send</StepLabel>
            <StepContent>
              <Box sx={{ p: 2 }}>
                <div>
                  <Step4
                    articlesToSend={selectedRows.filter((a) => a.id)}
                    articlesDetail={selectedRows}
                    sendToTabValue={step2Data.sendToTabValue}
                    emailTo={step2Data.emailTo}
                    emailsTo={step2Data.emailsTo}
                    emailFrom={step2Data.emailFrom}
                    isAttachedChart={isAttachedChart}
                    subject={step2Data.subject}
                    selectedEmailGroup={step2Data.selectedEmailGroup}
                  />

                  <Button
                    variant="contained"
                    onClick={() => {
                      setShowConfirmSend(true);
                    }}
                    sx={{ mt: 2, mr: 1 }}
                  >
                    Confirm & Send Emails
                  </Button>
                  <Button onClick={handleBack} sx={{ mt: 2, mr: 1 }}>
                    Back
                  </Button>
                </div>
                <Fade in={showAlertType !== ''}>
                  <Box mt={3}>
                    {showAlertType === 'success' && (
                      <Alert severity="success">
                        Emails sent successfully!
                      </Alert>
                    )}
                    {showAlertType === 'error' && (
                      <Alert severity="error">
                        There was an issue trying to send emails
                      </Alert>
                    )}
                  </Box>
                </Fade>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
      </Box>

      <ConfirmSend
        subject={step2Data.subject}
        numberOfUsers={
          step2Data.emailsTo && step2Data.emailsTo.length > 0
            ? step2Data.emailsTo.length
            : step2Data.emailTo
            ? 1
            : 0
        }
        sender={step2Data.emailFrom}
        open={showConfirmSend}
        setOpen={setShowConfirmSend}
        handleSend={sendEmail}
        sentMessage={
          showAlertType === 'success'
            ? 'Email(s) sent successfully!'
            : 'There was an issue trying to send emails'
        }
        sendEmailStatus={sendEmailStatus}
      />
    </ThemeProvider>
  );
};

export default Email;
