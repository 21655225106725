import React from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import slice from 'lodash/slice';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'title',
    headerName: 'Article Title',
    width: 600,
    editable: false,
  },
];

// Disable cell focus
const CustomDataGrid = styled(DataGrid)(() => ({
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
}));

const Step4 = ({
  articlesToSend,
  sendToTabValue,
  emailTo,
  emailsTo,
  emailFrom,
  selectedEmailGroup,
  subject,
}) => {
  return (
    <>
      <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
        Review Before Sending
      </Typography>
      <Typography variant="p" component="p" sx={{ mb: 2 }}>
        <b style={{ color: '#5b85d7' }}>{articlesToSend?.length} article(s)</b>{' '}
        will be sent:
      </Typography>
      <Box
        sx={{
          height: 450,
          width: '100%',
          backgroundColor: '#233246',
        }}
      >
        <CustomDataGrid
          rows={articlesToSend}
          columns={columns}
          pageSize={6}
          rowsPerPageOptions={[50]}
          // isCellEditable={false}
          disableSelectionOnClick
        />
      </Box>

      {/* email from  */}
      <Typography variant="p" component="p" sx={{ mt: 4 }}>
        Email Sender Address/ From:{' '}
        <b style={{ color: '#5b85d7' }}>{emailFrom}</b>
      </Typography>

      {/* email subject  */}
      {subject !== '' && (
        <Typography variant="p" component="p" sx={{ mt: 4 }}>
          Email Subject: <b style={{ color: '#5b85d7' }}>{subject}</b>
        </Typography>
      )}

      {/* email to  */}
      {sendToTabValue === 'single' && (
        <>
          <Typography variant="p" component="p" sx={{ mt: 4 }}>
            To{' '}
            <b style={{ color: '#5b85d7' }}>
              {emailTo ? 1 : 0} email {emailTo ? 'address' : 'addresses'}
            </b>
            :
          </Typography>

          <ul>
            <li>{emailTo}</li>
          </ul>
        </>
      )}

      {sendToTabValue === 'group' && (
        <>
          <Typography variant="p" component="p" sx={{ mt: 4 }}>
            To{' '}
            <b style={{ color: '#5b85d7' }}>
              {`${selectedEmailGroup?.name} (${
                selectedEmailGroup?.userCount || 0
              } users)`}
            </b>
            :
          </Typography>

          <ul>
            {slice(emailsTo, 0, 10)?.map((email, i) => (
              <li key={i}>{email}</li>
            ))}
          </ul>

          {selectedEmailGroup?.userCount > 10 && (
            <Typography
              variant="p"
              component="p"
              sx={{ mt: 2, fontStyle: 'italic' }}
            >
              +{selectedEmailGroup?.userCount - 10} more
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default Step4;
